import { useCurrentUserQuery } from '../../api/users/useCurrentUserQuery';
import { usePageContext } from '../../renderer/usePageContext';

import useTwitterSessionUserName from './useTwitterSessionUserName';

export default function useCurrentUserData() {
  const { userData } = usePageContext();

  const { twitterUsername, isLoading: twitterLoading } =
    useTwitterSessionUserName();

  const { data, isLoading: userQueryLoading } = useCurrentUserQuery(
    twitterUsername ?? '',
    !twitterUsername || !!userData,
  );

  return {
    data: userData ?? data,
    isLoading: userQueryLoading || twitterLoading,
  };
}
