import { Box, Button, Stack, Typography } from '@mui/material';

import { Cta } from '../../../api/types/getUserPoints';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { textWithLineBreaks } from '../../../lib/textWithLineBreaks';
import { BxpIcon } from '../../icons/bxp';
import { SxpIcon } from '../../icons/sxp';
import { QuestType } from '../Cards/QuestCard';
import { ModalContainer } from '../ModalContainer';

export interface QuestModalProps {
  title: string;
  headline: string;
  description: string;
  example?: string;
  cta: Cta;
  type: QuestType;
  important?: string;
  benefits?: string;
}

export function QuestModal({
  title,
  headline,
  description,
  example,
  cta,
  type,
  important,
  benefits,
}: QuestModalProps) {
  const { hideModal, showModal } = useGlobalModal();

  const onClose = () => {
    hideModal();
  };

  const handleAction = () => {
    if (cta.type === 'url') {
      window.open(cta.url, '_blank');
    } else if (cta.type === 'referral') {
      hideModal('QuestModal');
      showModal({ modalType: 'ReferFriendsModal' });
    }
  };

  return (
    <ModalContainer
      aria-label="Quest modal"
      aria-description="Quest modal"
      onClose={onClose}
    >
      <Stack
        sx={{
          width: { xs: '300px', md: '440px' },
          textAlign: 'left',
          alignSelf: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            py: 2,
            px: 4,
            backgroundColor: '#182920',
            borderRadius: '24px',
            alignSelf: 'center',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            top: '-20px',
          }}
        >
          {type === QuestType.BXP ? <BxpIcon /> : <SxpIcon />}
          <Box>
            {textWithLineBreaks(title, { color: '#38FF93', fontSize: '16px' })}
          </Box>
        </Box>
        <Box>
          {textWithLineBreaks(headline, {
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '29px',
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '20px',
            pt: 3,
          }}
        >
          <Box>
            {textWithLineBreaks(description, {
              fontWeight: 300,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#9B9FA3',
            })}
          </Box>
          {example && (
            <>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#9B9FA3',
                }}
              >
                Example:
              </Typography>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 8 }}
              >
                {textWithLineBreaks(example, {
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#F0F5FA',
                })}
              </Box>
            </>
          )}
        </Box>
        {benefits && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 8 }}>
            {textWithLineBreaks(benefits, {
              fontWeight: 300,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#F0F5FA',
            })}
          </Box>
        )}

        {important && (
          <>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '20px',
                pt: 8,
              }}
            >
              Important:
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 2 }}
            >
              {textWithLineBreaks(
                important,
                {
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#F0F5FA',
                },
                true,
              )}
            </Box>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 8,
          }}
        >
          <Button onClick={handleAction}>{cta.text}</Button>
        </Box>
      </Stack>
    </ModalContainer>
  );
}
