import { Backdrop, Box, Button, Modal, Typography } from '@mui/material';

import useGlobalModal from '../../../hooks/useGlobalModal';
import theme from '../../../theme/theme';

export function DepositSuccessfulModal() {
  const { hideModal } = useGlobalModal();

  const onClose = () => {
    hideModal();
  };

  return (
    <Modal
      aria-label="Deposit successful"
      aria-description="Deposit successful"
      open
      onClose={onClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{ overflow: 'scroll' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          position: 'absolute',
          top: '32px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          bgcolor: '#090C11',
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
          borderRadius: 2,
          maxHeight: 'calc(100vh - 48px)',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            width: 320,
            p: 10,
            overflowY: 'auto',
          }}
        >
          <Typography mb={4} variant="h5" color={theme.palette.success.main}>
            Deposit successful!
          </Typography>

          <Typography
            mb={6}
            variant="caption"
            align="center"
            color="text.secondary"
          >
            Deposit successful! It may take up to a few minute for your balance
            to update on Base.
          </Typography>

          <Button variant="outlined" fullWidth onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
