import { usePostHog } from 'posthog-js/react';

import useSupabase from '../api/supabase';

export function useUserAuthActions() {
  const supabaseClient = useSupabase();
  const posthog = usePostHog();

  return {
    linkTwitter,
    linkEmail,
    logoutTwitter,
  };

  async function linkTwitter() {
    return supabaseClient.auth.signInWithOAuth({
      provider: 'twitter',
      options: {
        redirectTo: window.location.href,
        scopes: 'email',
      },
    });
  }

  async function linkEmail(email: string) {
    return supabaseClient.auth.signInWithOtp({
      email,
      options: {
        // set this to false if you do not want the user to be automatically signed up
        shouldCreateUser: false,
      },
    });
  }

  async function logoutTwitter() {
    posthog.reset();
    await supabaseClient.auth.signOut();
  }
}
