import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useGetReferralStage } from '../api/useGetReferralStage';

interface CountdownContextProps {
  timeLeft: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null;
  multiplier: number | null;
  isLoading: boolean;
}

const CountdownContext = createContext<CountdownContextProps | undefined>({
  timeLeft: null,
  multiplier: null,
  isLoading: true,
});

export function useCountdown() {
  const context = useContext(CountdownContext);
  if (!context) {
    throw new Error('useCountdown must be used within a CountdownProvider');
  }
  return context;
}

const HOUR_IN_MS = 1000 * 60;
const MIN_IN_MS = HOUR_IN_MS * 60;
const DAY_IN_MS = MIN_IN_MS * 24;

export function CountdownProvider({ children }: PropsWithChildren) {
  const [timeLeft, setTimeLeft] =
    useState<CountdownContextProps['timeLeft']>(null);

  const { data, isLoading } = useGetReferralStage();

  useEffect(() => {
    if (!data?.end_date) {
      return;
    }
    const endDate = new Date(data.end_date);

    const interval = setInterval(() => {
      const utcNow = new Date();
      const distance = endDate.getTime() - utcNow.getTime();

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft(null);
      } else {
        const days = Math.floor(distance / DAY_IN_MS);
        const hours = Math.floor((distance % DAY_IN_MS) / MIN_IN_MS);
        const minutes = Math.floor((distance % MIN_IN_MS) / HOUR_IN_MS);
        const seconds = Math.floor((distance % HOUR_IN_MS) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [data?.end_date]);

  return (
    <CountdownContext.Provider
      value={{
        timeLeft,
        multiplier: data?.multiplier,
        isLoading: isLoading || !timeLeft,
      }}
    >
      {children}
    </CountdownContext.Provider>
  );
}
