import { Box, Link, Typography, useTheme } from '@mui/material';
import { ChevronRight } from '../../icons/chevronRight';
import { CheckRounded } from '../../icons';
import { InfoIcon } from '../../icons/InfoIcon';
import useGlobalModal from '../../../hooks/useGlobalModal';

export default function UserTypeCard({
  title,
  description,
  action,
  buttonText,
  isDone,
  disabled,
  isUsaUser = false,
}: {
  title: string | JSX.Element;
  description: string;
  action: () => void;
  buttonText?: string;
  isDone?: boolean;
  disabled?: boolean;
  isUsaUser?: boolean;
}) {
  const { showModal } = useGlobalModal();

  const theme = useTheme();

  const handleLinkClick = () => {
    showModal({ modalType: 'UsCitizenModal' });
  };

  return (
    <Box
      sx={{
        border: '2px solid',
        background: 'black',
        transition: '0.2s ease-in-out',
        '&:hover': {
          borderColor:
            !disabled && !isDone && !isUsaUser
              ? 'rgba(117, 117, 150, 0.9)'
              : '',
        },
        borderColor: '#262829',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        borderRadius: '12px',
        cursor: disabled || isUsaUser ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={() => {
        !disabled && !isUsaUser && action();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 5,
          py: 4,
          opacity: isUsaUser ? 0.3 : 1,
        }}
      >
        <Box>
          <Typography variant="h4">{title}</Typography>
          <Typography
            fontSize={14}
            fontWeight={300}
            pt={2}
            color="#ACAFB3"
            maxWidth="260px"
          >
            {description}
          </Typography>
        </Box>

        {isDone ? (
          <CheckRounded />
        ) : buttonText ? (
          <Typography color={theme.palette.primary.main} variant="body1">
            {buttonText}
          </Typography>
        ) : (
          <ChevronRight />
        )}
      </Box>
      {isUsaUser && (
        <Box sx={{ bgcolor: '#383B3D', px: 3, py: 3 }}>
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <InfoIcon color="white" />
            <Typography fontSize={14} fontWeight={300}>
              You can’t sign up as a creator.{' '}
              <Link
                component="button"
                onClick={handleLinkClick}
                fontSize={14}
                underline="always"
                sx={{
                  mt: -1,
                }}
              >
                {' '}
                Learn more
              </Link>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
