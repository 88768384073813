import { useMutation } from '@tanstack/react-query';
import useSupabase from '../../supabase';

export function useUnsubscribe() {
  const client = useSupabase();

  const mutationFn = async (data: {
    followed: string;
  }) => {
    const { followed: author } = data;

    const { data: res, error } = await client.rpc('unsubscribe_creator', { author });
    if (error) {
      throw new Error('Failed to Unsubscribe');
    }
    return res
  };

  return useMutation({ mutationFn });
}
