import { useMutation } from '@tanstack/react-query';

import useSupabase from '../../supabase';

interface IUpdateUserAvatarResponse {
  username: string;
  avatar: string;
}

export function useUserAvatarUpdateMutation() {
  const client = useSupabase();

  const mutationFn = async (params: IUpdateUserAvatarResponse) => {
    return updateUserAvatar(client, params).then((result: any) => result.data);
  };

  return useMutation({ mutationFn });
}

export function updateUserAvatar(client: any, user: IUpdateUserAvatarResponse) {
  return client.from('users').update(user).eq('username', user.username);
}
