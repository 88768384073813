export const REF_SEARCH_KEY = 'ref';

export function useReferSearchValue(): string | null {
  if (typeof window === 'undefined') return null;

  const referral = new URLSearchParams(window.location.search).get(
    REF_SEARCH_KEY,
  );
  if (referral) {
    sessionStorage.setItem(REF_SEARCH_KEY, referral);
    return referral;
  }

  return sessionStorage.getItem(REF_SEARCH_KEY);
}