export enum Environment {
  localhost = 'localhost',
  staging = 'staging',
  production = 'production',
}

const DEFAULT_ENV = Environment.localhost;

export function getCurrentEnvironment(): Environment {
  const environmentKey = import.meta.env.VITE_ENVIRONMENT;

  if (environmentKey === undefined) {
    return DEFAULT_ENV;
  }

  const releaseStageValue = Object.values(Environment).find(
    (value) => value === environmentKey,
  );

  return releaseStageValue ?? DEFAULT_ENV;
}
