import { useMemo } from 'react';

import { createBrowserClient } from '@supabase/ssr';

import { Database } from '../../_shared/types/supabase';

// TODO Ideally use correct type everywhere in the client codebase
export function getSupabaseBrowserClient() {
  return createBrowserClient<Database>(
    import.meta.env.VITE_SUPABASE_URL,
    import.meta.env.VITE_SUPABASE_ANON_KEY,
  );
}

function useSupabase() {
  return useMemo(() => {
    const supabaseClient = getSupabaseBrowserClient();
    if (!supabaseClient) {
      throw new Error('Supabase client is not initialized');
    }
    return supabaseClient;
  }, []);
}

export default useSupabase;
