import { useQuery } from '@tanstack/react-query';

async function fetchPrice() {
  const tokenAddress = import.meta.env.VITE_WETH_CONTRACT_ADDRESS;

  const fiatCurrency = import.meta.env.VITE_FIAT_CURRENCY;
  const searchParams = new URLSearchParams({
    networkKey: import.meta.env.VITE_NETWORK_KEY,
    tokenAddress,
    fiatCurrency,
  });

  const response = await fetch('/api/price?' + searchParams);
  const result = await response.json();
  const price = result[tokenAddress][fiatCurrency];
  return price;
}

export function useTokenPrice() {
  return useQuery({
    queryKey: ['token_price'],
    queryFn: fetchPrice,
  });
}
