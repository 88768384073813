import { Address } from 'viem';
import { useReadContracts } from 'wagmi';

import SubscribeRegistryAbi from '../../../assets/abi/SubscribeRegistry';
import { SubscribeRegistryPackages } from '../../../types/web3/SubscribeRegistryPackages';

type ContractSuccessResult = {
  error?: undefined;
  result: bigint;
  status: 'success';
};

type ContractFailureResult = {
  error: Error;
  result?: undefined;
  status: 'failure';
};

export type SubPriceContractResult =
  | ContractSuccessResult
  | ContractFailureResult;

export type AllSubPricesContractResult = [
  SubPriceContractResult,
  SubPriceContractResult,
];

export function useAllSubPricesReadMethod(address: Address | undefined) {
  const subscribeRegistryContract = {
    address: import.meta.env.VITE_SUBSCRIBE_REGISTRY_ADDRESS as Address,
    abi: SubscribeRegistryAbi,
    functionName: 'getSubPrice',
  };
  return useReadContracts({
    contracts: [
      {
        ...subscribeRegistryContract,
        args: [address, SubscribeRegistryPackages.MONTHLY],
      },
      {
        ...subscribeRegistryContract,
        args: [address, SubscribeRegistryPackages.LIFETIME],
      },
    ],
    query: {
      enabled: !!address,
      select: (data) => data as AllSubPricesContractResult,
    },
  });
}
