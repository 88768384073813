import { Box, Divider, Link, Typography } from '@mui/material';

import { AFFILIATE_LINK_PERCENTAGE } from '../../../constants/affiliateLinkPercentage';
import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';
import { AFFILATE_SEARCH_KEY } from '../../../hooks/useGetAffilateWalletFromUrl';
import { ICreator } from '../../../types/ICreator';
import { FeedbackBanner } from '../FeedbackBanner';
import { ModalContainer } from '../ModalContainer';

import { ShareModalBody } from './Content/ShareModalBody';
import { ListItem } from './ListItem';
import { LEARN_MORE_CREATOR_AFFILIATES } from '../../../constants/externalLinks';

export type AffiliateLinkCreator = Pick<
  ICreator,
  'avatar' | 'username' | 'is_subscribed'
>;

export interface AffiliateLinkModalProps {
  creator: AffiliateLinkCreator;
}

export function AffiliateLinkModal({ creator }: AffiliateLinkModalProps) {
  const { data: userData } = useCurrentUserData();

  const refLink = `${window.location.href}?${AFFILATE_SEARCH_KEY}=${userData?.wallet_address}`;

  return (
    <ModalContainer
      title={`Share ${creator.username}’s profile and earn together`}
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box display="flex" flexDirection="column" mt={10}>
        <Box display="flex" flexDirection="column" gap={3} mb={5}>
          <Typography fontSize={20} fontWeight={300}>
            How it works
          </Typography>

          <ListItem number={1}>Copy and Share the link below</ListItem>
          <ListItem number={2}>Your friends sign up via your link</ListItem>
          <ListItem number={3}>
            {'You earn '}
            <Typography component="span" fontWeight={600} fontSize={14}>
              {AFFILIATE_LINK_PERCENTAGE}% commission
            </Typography>
            {' (in $ETH) for each subscription you generate through this link'}
          </ListItem>
        </Box>
        <Box sx={{ display: 'flex', gap: 3, mb: 4 }}>
          <Typography sx={{ fontSize: '14px' }}>-</Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 300, color: '#F0F5FA' }}
          >
            Click{' '}
            <Link
              href={LEARN_MORE_CREATOR_AFFILIATES}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontSize: '14px' }}
            >
              here
            </Link>{' '}
            to learn more about creator affiliates
          </Typography>
        </Box>
        <FeedbackBanner
          type="success"
          title="The more people you refer, the more you can earn."
        />
        <Divider sx={{ mt: 6 }} />

        <ShareModalBody title="Your affiliate link" shareLink={refLink} />
      </Box>
    </ModalContainer>
  );
}
