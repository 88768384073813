import { PropsWithChildren } from 'react';

import { Typography, TypographyProps } from '@mui/material';

export function Text({
  children,
  ...props
}: PropsWithChildren & TypographyProps) {
  return (
    <Typography
      fontSize={14}
      fontWeight={300}
      color="text.secondary"
      whiteSpace="pre-line"
      {...props}
    >
      {children}
    </Typography>
  );
}
