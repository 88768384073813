import { Box } from '@mui/material';

export function StepIndicator({ step }: { step: number }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          border: '1px solid #38FF93',
          borderRadius: '100px',
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: { xs: '12px', md: '14px' },
          lineHeight: '14px',
        }}
      >
        {step}
      </Box>
      {step !== 3 && (
        <Box
          sx={{
            width: '1px',
            height: '42px',
            backgroundColor: '#38FF93',
            margin: '0 auto',
          }}
        />
      )}
    </Box>
  );
}
