import { useSnackbar } from 'notistack';

import { Box, BoxProps, Button, Typography, useTheme } from '@mui/material';

import { COINBASE_SMART_WALLET_LINK } from '../../../../constants/externalLinks';
import { truncAddress } from '../../../../lib/truncHash';
import { useWalletData } from '../../../../pages/app/creators/@id/subscribe/useWalletData';
import { RefreshButton } from '../../../icons';
import UserTypeCard from '../../Cards/UserTypeCard';
import { FeedbackBanner } from '../../FeedbackBanner';
import { usePostHogCapture } from '../../../../hooks/usePostHogCapture';

export function TopUpWalletContent({
  proceed,
  ...boxProps
}: { proceed?: () => void } & BoxProps) {
  const theme = useTheme();
  const { captureTopUpWallet } = usePostHogCapture();

  const { address, formatBalance, hasEnoughBalance, refetchBalance } =
    useWalletData();

  const { enqueueSnackbar } = useSnackbar();

  async function copyToClipoard() {
    try {
      await navigator.clipboard.writeText(address ?? '');
      enqueueSnackbar(`Copied ${address} to your clipboard.`, {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
    }
  }

  function openCoinbaseWallet() {
    captureTopUpWallet();
    window.open(COINBASE_SMART_WALLET_LINK, '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} {...boxProps}>
        <UserTypeCard
          title="Deposit from Coinbase"
          description="Use any device to deposit from Coinbase"
          action={openCoinbaseWallet}
          buttonText="Deposit"
        />
        <UserTypeCard
          title={
            <>
              Send ETH to your Base
              <br /> address
            </>
          }
          //  TODO: Replace with actual address
          description={truncAddress(address) ?? 'N/A'}
          action={copyToClipoard}
          buttonText="Copy address"
          disabled={!address}
        />
      </Box>

      {!hasEnoughBalance && (
        <Box pt={6}>
          <FeedbackBanner
            type="error"
            title="You don't have enough ETH in your wallet"
            body="Get ETH to cover gas for setting up your pricing as a creator and to spend on memberships to join communities as a follower."
          />
        </Box>
      )}
      <Box
        pt={6}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 5, md: 0 }}
        pb={{ xs: 10, md: 0 }}
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography color="#ACAFB3" variant="body1">
            Available balance
          </Typography>

          <Typography variant="body1">{formatBalance()} ETH</Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => refetchBalance()}
          >
            <Typography
              color={theme.palette.primary.main}
              pb="3px"
              variant="body2"
            >
              Refresh
            </Typography>
            <RefreshButton />
          </Box>
        </Box>

        <Button
          size="large"
          onClick={() => proceed?.()}
          disabled={!hasEnoughBalance}
          sx={{ width: { xs: '100%', md: 'auto' } }}
        >
          Proceed
        </Button>
      </Box>
    </>
  );
}
