import { formatUnits } from 'viem';

export function formatBigint(
  valueInWei: bigint | null | undefined,
  decimals: number,
) {
  return valueInWei || valueInWei === 0n
    ? formatUnits(valueInWei, decimals)
    : 'N/A';
}
