import { Avatar, AvatarProps } from '@mui/material';

export default function UserAvatar({
  src,
  name,
  size = 70,
  walletAddress,
  ...props
}: AvatarProps & {
  src?: string;
  name?: string;
  size?: number;
  walletAddress?: string;
}) {
  return (
    <Avatar
      src={src || 'https://robohash.org/' + walletAddress}
      alt={name}
      sx={{
        width: size,
        height: size,
      }}
      {...props}
    >
      {name?.slice(0, 2).toUpperCase()}
    </Avatar>
  );
}
