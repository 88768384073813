import { useSessionUser } from '../../providers/SessionProvider';

export default function useTwitterAvatarUrl() {
  const { data } = useSessionUser();

  const bigAvatarUrl = data?.user_metadata?.avatar_url?.replace(
    'normal',
    '400x400',
  );

  return bigAvatarUrl;
}
