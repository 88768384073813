import { SimulateContractErrorType } from '@wagmi/core';

import { FeedbackInfo } from '../components/shared/FeedbackBanner';

export interface FeedbackDetails extends FeedbackInfo {
  message?: string;
}

export function getErrorDetails(
  failureReason: SimulateContractErrorType | null,
  errorsMap: Record<string, FeedbackDetails>,
): FeedbackDetails | null {
  const failureCause = failureReason?.cause as any;
  const reasonCode = failureCause?.reason as string;
  const knownError = errorsMap[reasonCode];
  if (knownError) {
    return knownError;
  }

  if (failureCause?.shortMessage) {
    return {
      title: 'Unexpected error in contract call.',
      body: failureCause?.shortMessage,
      type: 'error',
      message: failureCause?.message,
    };
  }

  return null;
}
