import { Address, erc20Abi } from 'viem';
import { useAccount, useBalance, useReadContract } from 'wagmi';

import { useMainTokenInfo } from '../../../../../hooks/useMainTokenInfo';
import formatBigintWithDecimals from '../../../../../lib/formatBigintWithDecimals';

export function useWalletData() {
  const { address } = useAccount();
  const { data, refetch: refetchBalance, isLoading } = useBalance({ address });
  const balance = data?.value;
  const { decimals } = useMainTokenInfo();
  const formattedBalance = formatBigintWithDecimals(balance, decimals);
  const hasEnoughBalance = !!balance;

  const formatBalance = (fractionDigits: number = 6) => {
    return formatBigintWithDecimals(balance, decimals, fractionDigits);
  };

  return {
    address,
    balance: balance,
    formattedBalance,
    formatBalance,
    hasEnoughBalance,
    refetchBalance,
    isLoading,
  };
}
