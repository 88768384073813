import { useEffect, useState } from 'react';
import { formatUnits } from 'viem';

import { useCreatorByUsernameQuery } from '../../../../../api/users/useCreatorByUsernameQuery';
import { SubscriptionPlanType } from '../../../../../types/SubscriptionPlanType';
import { useAllSubPricesReadMethod } from '../../../../../web3/hooks/read/useGetSubPricesReadMethod';

export function usePriceBySelectedPlan(
  username: string | undefined,
  planSelected: SubscriptionPlanType,
) {
  const [selectedPriceInWei, setSelectedPriceInWei] = useState<
    bigint | undefined
  >(undefined);
  const { data: creator } = useCreatorByUsernameQuery(username);

  const { data: subPrices, isSuccess } = useAllSubPricesReadMethod(
    creator?.wallet_address,
  );

  useEffect(() => {
    if (subPrices && isSuccess) {
      const subPrice =
        planSelected === SubscriptionPlanType.LIFETIME
          ? subPrices[1]
          : subPrices[0];

      setSelectedPriceInWei(subPrice.result);
    }
  }, [subPrices, isSuccess, planSelected]);

  const selectedPriceInEthStr = selectedPriceInWei
    ? formatUnits(selectedPriceInWei, 18)
    : 'N/A';
  return { selectedPriceInWei, selectedPriceInEthStr };
}
