import { Theme } from '@emotion/react';
import { SxProps, Typography } from '@mui/material';

export const textWithLineBreaks = (
  text: string | undefined,
  sx: SxProps<Theme> | undefined,
  hasBulletPoints: boolean = false,
) => {
  if (!text) return null;

  return text.split('\n').map((line, index) => (
    <Typography key={index} sx={sx}>
      {hasBulletPoints && (
        <Typography component="span" pr={2}>
          •
        </Typography>
      )}
      {line}
    </Typography>
  ));
};
