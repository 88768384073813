import { useQuery } from '@tanstack/react-query';

import useSupabase from './supabase';

export function useGetReferralStage() {
  const client = useSupabase();
  const queryKey = ['referral_multiplier'];

  const queryFn = async () => {
    const { data, error } = await client.rpc('get_referral_stage');
    if (error) throw new Error(error.message);
    return data;
  };

  return useQuery<any>({
    queryKey,
    queryFn,
  });
}
