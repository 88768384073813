import { SupabaseClient } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

import useSupabase from '../supabase';
import { UserProfile } from '../types/getUserProfile';

export function useGetUserProfile({ username }: { username: string }) {
  const client = useSupabase();
  const queryKey = ['get_user_profile', username];

  const queryFn = async (): Promise<UserProfile> => {
    return getUserProfile(client, { username }).then(
      (result: any) => result.data,
    );
  };

  return useQuery<UserProfile>({ queryKey, queryFn });
}

export function getUserProfile(
  client: SupabaseClient<any, 'public', any>,
  { username }: { username: string },
) {
  return client.rpc('get_user_profile', {
    p_username: username,
  });
}
