export function ChevronRight({
  stroke = '#F9FAFB',
  size = 20,
  changeDirection = false,
}: {
  stroke?: string;
  size?: number;
  changeDirection?: boolean;
}) {
  return (
    <svg
      className={changeDirection ? 'rotate-180' : ''}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: changeDirection ? 'rotate(180deg)' : 'none',
        transition: 'transform 0.3s ease',
      }}
    >
      <path
        d="M6.4585 3.52417L13.1252 10.1908L6.4585 16.8575"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
