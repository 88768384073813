import { renderToString } from 'react-dom/server';

import { Box, styled } from '@mui/material';

import { LoadingLogoIcon } from './icons';

const LOADING_LOGO_SVG = renderToString(<LoadingLogoIcon />);

export const GlobalLoadingIcon = styled(Box)(() => ({
  position: 'relative',
  '&:before, &:after': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    content: `url('data:image/svg+xml,${LOADING_LOGO_SVG}')`,
    transformOrigin: 'center center',
  },
  '&:after': {
    animation: 'loading 1s infinite',
    transform: 'translate(-50%, -50%) perspective(300px) translateZ(0px)',
  },
  '@keyframes loading': {
    to: {
      transform: 'translate(-50%, -50%) perspective(300px) translateZ(180px)',
      opacity: 0,
    },
  },
}));

export const GlobalLoading = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    }}
  >
    <GlobalLoadingIcon />
  </Box>
);
