export { render as onRenderClient };

import ReactDOM from 'react-dom/client';

import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { PageShell } from './PageShell';
import type { PageContextClient } from './types';

let root: null | ReactDOM.Root;
let dehydratedState: unknown;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 1000 * 60 * 10,
      staleTime: 30 * 1000,
      retryDelay: 2000,
    },
  },
});
// This render() hook only supports SSR, see https://vike.dev/render-modes for how to modify render() to support SPA
async function render(pageContext: PageContextClient) {
  const { Page, pageProps } = pageContext;

  if (!dehydratedState && pageContext.dehydratedState) {
    dehydratedState = pageContext.dehydratedState;
  }
  const page = (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <PageShell pageContext={pageContext}>
          <Page {...pageProps} />
        </PageShell>
      </HydrationBoundary>
    </QueryClientProvider>
  );

  const container = document.getElementById('root');
  if (!container) throw new Error('DOM element #root not found');
  // SPA
  if (!container?.innerHTML || !pageContext.isHydration) {
    if (!root) {
      root = ReactDOM.createRoot(container);
    }
    root.render(page);
    // SSR
  } else {
    root = ReactDOM.hydrateRoot(container, page);
  }
}

/* To enable Client-side Routing:
export const clientRouting = true
// !! WARNING !! Before doing so, read https://vike.dev/clientRouting */
