import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import { Address, isAddress, zeroAddress } from 'viem';
import { useAccount } from 'wagmi';
import {
  useCallsStatus,
  useCapabilities,
  useWriteContracts,
} from 'wagmi/experimental';

import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Divider,
  Link,
  Skeleton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useSetUserTypeMutation } from '../api/users/mutations/useSetUserTypeMutation';
import { useCreatorByUsernameQuery } from '../api/users/useCreatorByUsernameQuery';
import { useGetUserProfile } from '../api/users/useGetUserProfile';
import { useUserByWalletAddressQuery } from '../api/users/useUserByWalletAddressQuery';
import { LEARN_MORE_PULL_PAYMENTS_TOC_LINK } from '../constants/externalLinks';
import { GET_CREATOR_PATH } from '../constants/routings';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';
import useGlobalModal from '../hooks/useGlobalModal';
import { usePostHogCapture } from '../hooks/usePostHogCapture';
import { usePriceInUsd } from '../hooks/usePriceInUsd';
import navigate from '../lib/navigate';
import { truncAddress } from '../lib/truncHash';
import { usePriceBySelectedPlan } from '../pages/app/creators/@id/subscribe/usePriceBySelectedPlan';
import { useWalletData } from '../pages/app/creators/@id/subscribe/useWalletData';
import { SubscriptionPlanType } from '../types/SubscriptionPlanType';
import { UserType } from '../types/UserType';
import { useAllowanceReadMethod } from '../web3/hooks/ERC20/useAllowanceReadMethod';
import { getApproveContract } from '../web3/hooks/ERC20/useApproveSimulateContract';
import { useApproveWriteMethod } from '../web3/hooks/ERC20/useApproveWriteMethod';
import { getDepositContract } from '../web3/hooks/ERC20/useDepositSimulateContract';
import { getSubscribeContract } from '../web3/hooks/SubscribeRegistry/useSubscribeSumulateContract';
import { useSubscribeWriteMethod } from '../web3/hooks/SubscribeRegistry/useSubscribeWriteMethod';

import { PULL_PAYMENTS_MODAL_APPROVED_KEY } from './shared/Modals/PullPaymentApprovalModal';
import UserAvatar from './shared/UserAvatar';
import { GlobalLoading } from './GlobalLoading';
import { TermsAndPolicyTypography } from './TermsAndPolicyTypography';
import { SubscriptionProps } from '../types/Posthog';

const DEFAULT_VALUE = 'N/A';

enum SubscriptionStep {
  ApproveSubscription = 'ApproveSubscription',
  ApprovePullPayment = 'ApprovePullPayment',
  Subscribe = 'Subscribe',
}

export function SubscriptionPanel({
  planSelected,
  username,
  affilateWalletParam,
  goToTopup,
  ...props
}: {
  planSelected: SubscriptionPlanType;
  username: string;
  affilateWalletParam: string | null;
  goToTopup?: () => void;
} & BoxProps) {
  const showRefferedBySection = !!affilateWalletParam;

  const isAffilateWalletValid =
    showRefferedBySection && isAddress(affilateWalletParam);

  const referredAddressFromParams = isAffilateWalletValid
    ? affilateWalletParam
    : null;

  const { data: referredUser, isLoading: referredLoading } =
    useUserByWalletAddressQuery(referredAddressFromParams);

  const { data: creator } = useCreatorByUsernameQuery(username);
  const { selectedPriceInWei, selectedPriceInEthStr } = usePriceBySelectedPlan(
    username,
    planSelected,
  );

  const { convertToUSD } = usePriceInUsd(DEFAULT_VALUE);
  const { enqueueSnackbar } = useSnackbar();

  const selectedPriceInUsdStr = selectedPriceInEthStr
    ? convertToUSD(+selectedPriceInEthStr)
    : DEFAULT_VALUE;

  const { data: userData } = useCurrentUserData();

  const { address, balance, formatBalance, hasEnoughBalance } = useWalletData();

  const subscribeRegistryAddress = import.meta.env
    .VITE_SUBSCRIBE_REGISTRY_ADDRESS as Address;

  const { data: allowance, refetch: refetchAllowance } = useAllowanceReadMethod(
    address,
    subscribeRegistryAddress,
  );

  const onApproveSuccess = (approveHash: string) => {
    enqueueSnackbar(`Approved successfully! Tx hash: ${approveHash}`, {
      variant: 'success',
    });
    refetchAllowance();
    subscribeSimulateResponse.refetch();
  };

  const { writeApproveIfPossible, approveDisabled, approveFailureMsg } =
    useApproveWriteMethod(
      subscribeRegistryAddress,
      selectedPriceInWei,
      onApproveSuccess,
    );

  const handleApprove = () => {
    if (disabledBecauseOfPullPayment) {
      return;
    }

    writeApproveIfPossible();
  };

  const onApprovePaymentCollectorSuccess = (hash: string) => {
    enqueueSnackbar(`Approved Payment Collector successfully!`, {
      variant: 'success',
    });

    pullPaymentRefetchAllowance();
  };

  const paymentCollectorAddress = import.meta.env
    .VITE_PAYMENT_COLLECTOR_ADDRESS as Address;

  const { data: pullPaymentAllowance, refetch: pullPaymentRefetchAllowance } =
    useAllowanceReadMethod(address, paymentCollectorAddress);

  const sumToApprovePullPayment = ethers.MaxUint256 - 1n;

  const {
    writeApproveIfPossible: writePullPaymentApproveIfPossible,
    approveDisabled: pullPaymentApproveDisabled,
    approveFailureMsg: pullPaymentApproveFailureMsg,
    approveSimulationResult: pullPaymentApproveSimulationResult,
  } = useApproveWriteMethod(
    paymentCollectorAddress,
    sumToApprovePullPayment,
    onApprovePaymentCollectorSuccess,
  );

  const handlePullPaymentApprove = () => {
    if (disabledBecauseOfPullPayment) {
      return;
    }

    writePullPaymentApproveIfPossible();
  };

  const {
    captureOnSignUpComplete,
    captureSetupBasicProfile,
    captureMonthlySubs,
    captureLifetimeSubs,
  } = usePostHogCapture();
  const setUserTypeMutation = useSetUserTypeMutation();
  const isSuccessUserTypeMutation = setUserTypeMutation.isSuccess;
  const [isPollingSub, setIsPollingSub] = useState(false);
  const { refetch } = useGetUserProfile({ username });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  // Posthog events
  useEffect(() => {
    if (isSuccessUserTypeMutation) {
      const userType = setUserTypeMutation.variables.userType;
      captureOnSignUpComplete(userType);
      captureSetupBasicProfile(userType);
    }
  }, [isSuccessUserTypeMutation]);

  // Redirect to creator page after successful subscription.
  // TODO - Ideally add loading with realtime ??
  useEffect(() => {
    let intervalId: any;

    if (isPollingSub) {
      intervalId = setInterval(async () => {
        const { data } = await refetch();
        if (data?.is_subscribed) {
          clearInterval(intervalId);
          navigate(GET_CREATOR_PATH(username));
        }
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPollingSub, refetch, username]);

  const onSubscribeSuccess = () => {
    enqueueSnackbar(`Subscribed successfully!`, {
      variant: 'success',
    });

    const subscriptionProps: SubscriptionProps = {
      creator_name: username,
      pricing: selectedPriceInEthStr,
      timestamp: new Date().toISOString(),
    };

    // Set user type to follower by default on first subscription
    if (userData?.id && !userData?.user_type) {
      setUserTypeMutation.mutate({
        userId: userData.id,
        userType: UserType.FOLLOWER,
      });

      subscriptionProps.user_type = UserType.FOLLOWER;
    }

    planSelected === SubscriptionPlanType.MONTHLY
      ? captureMonthlySubs(subscriptionProps)
      : captureLifetimeSubs(subscriptionProps);

    setIsPollingSub(true);
  };

  const creatorAddress = creator?.wallet_address;
  const subberAddress = userData?.wallet_address;
  const referredAddress = referredUser?.wallet_address;
  const {
    subscribeSimulateResponse,
    subscribeDisabled,
    subscribeFailureMsg,
    writeSubscribeIfPossible,
  } = useSubscribeWriteMethod(
    creatorAddress,
    subberAddress,
    planSelected,
    referredAddress ?? zeroAddress,
    allowance,
    onSubscribeSuccess,
  );

  const handleSubscribe = () => {
    if (disabledBecauseOfPullPayment) {
      return;
    }

    writeSubscribeIfPossible();
  };

  const [pullPaymentSelected, setPullPaymentSelected] = useState(false);

  const disabledBecauseOfPullPayment =
    !pullPaymentSelected && planSelected === SubscriptionPlanType.MONTHLY;

  const handlePullPaymentsChange = (event: any) => {
    const newChecked = event.target.checked;
    setPullPaymentSelected(newChecked);
  };

  const { data: capabilities } = useCapabilities();
  const { chainId } = useAccount();
  const isUseBatching =
    capabilities && chainId && !!capabilities[chainId]?.atomicBatch?.supported;

  const { data: batchTxId, writeContracts } = useWriteContracts();
  const { data: callsStatus, failureReason: batchSubscribeFailureReason } =
    useCallsStatus({
      id: batchTxId as string,
      query: {
        enabled: !!batchTxId,
        // Poll every second until the calls are confirmed
        refetchInterval: (data) =>
          data.state.data?.status === 'CONFIRMED' ? false : 1000,
      },
    });

  const batchSubscribeFailureMsg = (batchSubscribeFailureReason?.cause as any)
    ?.shortMessage as string | undefined;

  const approved =
    !!allowance && !!selectedPriceInWei && allowance >= selectedPriceInWei;

  const pullPaymentApproved =
    !!pullPaymentAllowance && pullPaymentAllowance >= sumToApprovePullPayment;

  const disabledBatchMethod =
    !creatorAddress ||
    !selectedPriceInWei ||
    !subscribeRegistryAddress ||
    !subberAddress ||
    planSelected === null ||
    referredLoading ||
    disabledBecauseOfPullPayment;

  const { showModal, hideModal } = useGlobalModal();

  const handleSubscribeClick = () => {
    if (disabledBatchMethod) {
      return;
    }

    const value = localStorage.getItem(PULL_PAYMENTS_MODAL_APPROVED_KEY);
    if (value?.toLowerCase() === 'true') {
      subscribeWithAllApprovals();
      return;
    }

    showModal({
      modalType: 'PullPaymentApprovalModal',
      modalProps: {
        onContinueClick: () => {
          localStorage.setItem(PULL_PAYMENTS_MODAL_APPROVED_KEY, 'true');

          hideModal('PullPaymentApprovalModal');

          subscribeWithAllApprovals();
        },
      },
    });
  };

  const subscribeWithAllApprovals = () => {
    if (disabledBatchMethod) {
      return;
    }

    const contracts = [];
    if (!approved) {
      contracts.push(
        getApproveContract(subscribeRegistryAddress, selectedPriceInWei),
      );
    }

    if (planSelected === SubscriptionPlanType.MONTHLY && !pullPaymentApproved) {
      contracts.push(
        getApproveContract(paymentCollectorAddress, sumToApprovePullPayment),
      );
    }

    writeContracts(
      {
        contracts: [
          ...contracts,
          getDepositContract(selectedPriceInWei),
          getSubscribeContract(
            creatorAddress,
            subberAddress,
            planSelected,
            referredAddress,
          ),
        ],
      },
      {
        onError: (error) => {
          enqueueSnackbar(
            `Subscription failed, because of error: ${error.message}`,
            {
              variant: 'error',
            },
          );
        },
        onSuccess: onSubscribeSuccess,
      },
    );
  };

  const subscriptionStep = (() => {
    if (!approved) {
      return SubscriptionStep.ApproveSubscription;
    }

    if (planSelected === SubscriptionPlanType.MONTHLY && !pullPaymentApproved) {
      return SubscriptionStep.ApprovePullPayment;
    }

    return SubscriptionStep.Subscribe;
  })();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      {...props}
      sx={{ pt: { xs: 5, md: 0 } }}
    >
      {isPollingSub && <GlobalLoading />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
          Subscription cost
        </Typography>
        <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
          {selectedPriceInEthStr} ETH
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
          Frequency
        </Typography>
        <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
          {planSelected}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
          Due now
        </Typography>
        <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
          {selectedPriceInEthStr} ETH
        </Typography>
      </Box>
      <Typography textAlign="end" sx={{ fontSize: { xs: '14px', md: '16px' } }}>
        ≈ {selectedPriceInUsdStr} USD
      </Typography>

      {planSelected === SubscriptionPlanType.MONTHLY && (
        <Box display="flex" alignItems="center" pt={4}>
          <Checkbox
            sx={{
              ml: -2,
            }}
            size="small"
            color="primary"
            checked={pullPaymentSelected}
            onChange={handlePullPaymentsChange}
            name="termsAndConditions"
            disabled={pullPaymentSelected}
          />

          <Typography variant="body1" mr={2}>
            Pull payments Terms&Conditions
          </Typography>

          <Link
            href={LEARN_MORE_PULL_PAYMENTS_TOC_LINK}
            target="_blank"
            rel="noopener noreferrer"
            fontSize={{ xs: '12px', md: '16px' }}
          >
            Learn More
          </Link>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 3, md: 0 },
          justifyContent: 'space-between',
          paddingTop: { xs: 0, md: 7 },
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            Available balance
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '14px', md: '16px' } }}
            color={!balance ? 'error' : 'white'}
          >
            {formatBalance()} ETH
          </Typography>
        </Box>

        {hasEnoughBalance || !goToTopup ? (
          !isUseBatching ? (
            <>
              {subscriptionStep === SubscriptionStep.ApproveSubscription && (
                <Tooltip title={approveFailureMsg} enterTouchDelay={0}>
                  <Box display="flex">
                    <Button
                      size="large"
                      disabled={disabledBecauseOfPullPayment || approveDisabled}
                      onClick={handleApprove}
                    >
                      Approve {selectedPriceInEthStr} ETH
                    </Button>
                  </Box>
                </Tooltip>
              )}

              {subscriptionStep === SubscriptionStep.ApprovePullPayment && (
                <Tooltip
                  title={pullPaymentApproveFailureMsg}
                  enterTouchDelay={0}
                >
                  <Box display="flex">
                    <Button
                      size="large"
                      variant="contained"
                      disabled={
                        disabledBecauseOfPullPayment ||
                        pullPaymentApproveDisabled
                      }
                      onClick={handlePullPaymentApprove}
                    >
                      Approve Pull Payment
                    </Button>
                  </Box>
                </Tooltip>
              )}

              {subscriptionStep === SubscriptionStep.Subscribe && (
                <Tooltip title={subscribeFailureMsg} enterTouchDelay={0}>
                  <Box display="flex">
                    <Button
                      size="large"
                      variant="contained"
                      disabled={
                        referredLoading ||
                        disabledBecauseOfPullPayment ||
                        subscribeDisabled
                      }
                      onClick={handleSubscribe}
                    >
                      Subscribe
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip title={batchSubscribeFailureMsg} enterTouchDelay={0}>
              <Box>
                <Button
                  onClick={handleSubscribeClick}
                  disabled={
                    disabledBatchMethod ||
                    (callsStatus && callsStatus.status !== 'CONFIRMED')
                  }
                >
                  Subscribe
                </Button>
              </Box>
            </Tooltip>
          )
        ) : (
          <Button
            size={isMobile ? 'small' : 'large'}
            variant="contained"
            sx={{ width: { xs: '100%', md: 'auto' }, mb: { xs: 5, md: 0 } }}
            onClick={() => goToTopup?.()}
          >
            Top up wallet
          </Button>
        )}
      </Box>

      {showRefferedBySection && (
        <>
          <Divider sx={{ my: 6 }} />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="16px" fontWeight="300" color="text.secondary">
              Referred by
            </Typography>

            {referredLoading && (
              <Skeleton variant="rounded" height={14} width={80} />
            )}

            {!referredLoading && (
              <>
                {!referredUser?.wallet_address && (
                  <Typography fontSize="14px" fontWeight="400" color="error">
                    Invalid Referrer
                  </Typography>
                )}

                {!!referredUser?.wallet_address && (
                  <Box display="flex" alignItems="center" gap={2}>
                    <UserAvatar
                      name={referredUser.username}
                      src={referredUser.avatar}
                      walletAddress={referredUser.wallet_address}
                      size={32}
                    />

                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={0.5}
                      justifyContent="center"
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color="text.primary"
                      >
                        {referredUser.username}
                      </Typography>

                      <Typography
                        fontSize={12}
                        fontWeight={300}
                        color="text.secondary"
                      >
                        {truncAddress(referredUser.wallet_address)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      )}

      {hasEnoughBalance && <TermsAndPolicyTypography mt={8} />}
    </Box>
  );
}
