import { useState, useEffect } from 'react';
import { useConnect, useAccount } from 'wagmi';
import {
  Button,
  ButtonProps,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export function CoinbaseSmartWalletButton({ ...props }: ButtonProps) {
  const { connect, connectors } = useConnect();
  const { isConnected } = useAccount();
  const [isVerifying, setIsVerifying] = useState(true);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    // Simulate an async operation to verify connection status
    const verifyConnection = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulating a delay
      setIsVerifying(false);
    };

    verifyConnection();
  }, []);

  if (isVerifying) {
    return <CircularProgress size={30} />;
  }

  return (
    <Button
      size="large"
      variant="contained"
      onClick={() => connect({ connector: connectors[0] })}
      {...props}
    >
      {isConnected
        ? 'Connected'
        : isMdUp
        ? 'Connect Smart Wallet'
        : 'Connect Wallet'}
    </Button>
  );
}
