import { useSwitchChain } from 'wagmi';

import { Box, Button, Typography } from '@mui/material';

import { useIsAppReady } from '../../hooks/useIsAppReady';
import {
  GlobalConnectErrors,
  useGlobalConnectError,
} from '../../hooks/useWalletConnectError';
import { ChevronRight } from '../icons/chevronRight';

import { EmailNotLinkedWarning } from './EmailNotLinkedWarning';
import { GlobalWarningWrapper } from './GlobalWarningWrapper';
import { typographyProps } from './typographyProps';

export function GlobalWarningContainer() {
  const { switchChain, chains } = useSwitchChain();
  const { errorType } = useGlobalConnectError();
  const { isReady } = useIsAppReady();

  if (!isReady || !errorType) {
    return null;
  }

  if (errorType === GlobalConnectErrors.NoWalletConnected) {
    return (
      <GlobalWarningWrapper>
        <Typography {...typographyProps}>
          ⚠️ You don`t have a wallet connected. Connect your wallet by using the
          “Connect wallet” button below
        </Typography>
      </GlobalWarningWrapper>
    );
  }

  if (errorType === GlobalConnectErrors.WrongNetwork) {
    return (
      <GlobalWarningWrapper>
        <Box display="flex" alignItems="center">
          <Typography {...typographyProps}>
            ⚠️ Wrong network selected for your wallet. Select “Base” as your
            wallet network
          </Typography>

          <Button
            variant="text"
            color="secondary"
            endIcon={<ChevronRight stroke="white" size={16} />}
            onClick={() => switchChain({ chainId: chains[0]?.id })}
            sx={{ py: 0 }}
          >
            Switch network
          </Button>
        </Box>
      </GlobalWarningWrapper>
    );
  }

  if (errorType === GlobalConnectErrors.WalletNotLinked) {
    return (
      <Typography {...typographyProps}>
        ⚠️ Your wallet not linked to this account
      </Typography>
    );
  }

  if (errorType === GlobalConnectErrors.DifferFromLinkedWallet) {
    return (
      <Typography {...typographyProps}>
        ⚠️ Your wallet doesn’t match the address on our database
      </Typography>
    );
  }

  //if (errorType === GlobalConnectErrors.EmailNotLinked) {
  //  return <EmailNotLinkedWarning />;
  //}
  return null;
}
