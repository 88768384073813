export function LogoWithText({
  width = 167,
  height = 32,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 167 32"
    >
      <path
        fill="#fff"
        d="M90.481 18.546c-2.657 0-4.685-.676-6.084-2.026-1.384-1.366-2.075-3.257-2.075-5.675 0-2.528.676-4.482 2.028-5.864C85.717 3.6 87.753 2.91 90.458 2.91c.927 0 1.752.07 2.476.212.739.141 1.446.33 2.122.565v3.32a11.19 11.19 0 0 0-4.316-.847c-1.572 0-2.727.377-3.466 1.13-.723.754-1.085 1.939-1.085 3.556 0 1.538.385 2.669 1.156 3.391.77.722 1.918 1.083 3.443 1.083.739 0 1.47-.063 2.193-.188a10.796 10.796 0 0 0 2.099-.636v3.344c-.66.22-1.36.393-2.1.518-.738.126-1.571.189-2.499.189Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M98.234 16.662c1.29 1.256 3.168 1.884 5.637 1.884 2.468 0 4.347-.628 5.636-1.884 1.289-1.256 1.933-3.21 1.933-5.864 0-2.81-.644-4.828-1.933-6.052-1.289-1.225-3.168-1.837-5.636-1.837-2.469 0-4.347.612-5.637 1.837-1.289 1.224-1.933 3.242-1.933 6.052 0 2.653.644 4.608 1.933 5.864Zm8.419-2.426c-.581.722-1.509 1.083-2.782 1.083-1.29 0-2.233-.36-2.83-1.083-.582-.738-.873-1.884-.873-3.438 0-1.696.291-2.889.873-3.58.597-.706 1.54-1.06 2.83-1.06 1.273 0 2.201.354 2.782 1.06.598.691.896 1.884.896 3.58 0 1.554-.298 2.7-.896 3.438Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M143.708 18.31c.88.157 1.823.236 2.829.236 2.139 0 3.726-.409 4.764-1.225 1.038-.832 1.557-1.915 1.557-3.25 0-.753-.126-1.428-.378-2.025-.251-.612-.707-1.146-1.368-1.601-.66-.471-1.611-.872-2.853-1.201a38.626 38.626 0 0 1-1.91-.565c-.456-.157-.778-.33-.967-.519-.189-.204-.283-.47-.283-.8 0-.565.259-.926.778-1.083.535-.157 1.226-.236 2.075-.236.645 0 1.305.063 1.981.188.692.126 1.376.322 2.052.59v-3.25c-.519-.173-1.195-.322-2.028-.448a15.349 15.349 0 0 0-2.571-.212c-2.028 0-3.56.385-4.598 1.154-1.038.77-1.557 1.876-1.557 3.32 0 .801.134 1.476.401 2.026.268.549.708 1.02 1.321 1.412.613.377 1.438.723 2.476 1.037.881.25 1.572.47 2.075.659.519.173.889.377 1.109.612.236.236.353.55.353.942 0 .581-.235.958-.707 1.13-.456.173-1.156.26-2.099.26-.754 0-1.556-.095-2.405-.283a13.044 13.044 0 0 1-2.288-.73v3.226c.629.283 1.376.495 2.241.636ZM157.837 6.065V18.31h3.797V6.065h4.457v-2.92H153.38v2.92h4.457ZM135.508 18.31V3.145h3.797V18.31h-3.797ZM119.572 3.145V18.31h3.796V9.267l6.462 9.043h3.278V3.145h-3.82v9.066l-6.462-9.066h-3.254ZM113.377 18.31V3.145h3.797V18.31h-3.797ZM73.065 6.065V18.31h3.797V6.065h4.457v-2.92H68.608v2.92h4.457ZM59.948 18.31V3.145h3.797V15.39h7.169v2.92H59.947Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M48.12 3.145 42.32 18.31h3.962l1.344-3.438h5.07l1.368 3.438h3.962L52.224 3.145H48.12Zm4.056 8.925h-4.032l2.004-5.275 2.028 5.275Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M31.94 30.546V9.823c0-.337-.134-.66-.372-.897L23.19.558a1.27 1.27 0 0 0-.898-.372H1.547a1.27 1.27 0 0 0-1.27 1.269v29.09c0 .701.569 1.269 1.27 1.269H30.67a1.27 1.27 0 0 0 1.27-1.268ZM1.547 0C.743 0 .091.651.091 1.455v29.09C.09 31.35.743 32 1.547 32H30.67c.804 0 1.456-.651 1.456-1.454V9.823c0-.386-.153-.756-.426-1.029L23.322.426A1.457 1.457 0 0 0 22.292 0H1.547Z"
        clipRule="evenodd"
      />
      <path
        fill="#38FF93"
        fillRule="evenodd"
        d="m27.758 27.999-9.06-24.18h-3.412l-3.526 9.44-4.193-2.261-1.194 2.407 4.439 2.394-.618 1.653-4.54-2.45-1.195 2.409 4.792 2.567-2.996 8.02h3.153l2.377-6.663L24.225 28l.124-.002h3.409ZM14.166 14.556l2.745-7.824h.065l3.973 11.482-6.783-3.658Zm8.084 7.412-8.984-4.845-.586 1.67 10.42 5.619-.85-2.444ZM140.244 22v7.273h3.541c.715 0 1.256-.17 1.624-.508.369-.34.553-.863.553-1.57 0-.505-.109-.885-.327-1.14-.211-.264-.5-.441-.869-.532.286-.135.508-.335.666-.598.165-.271.248-.606.248-1.005 0-.61-.169-1.08-.508-1.412-.33-.339-.853-.508-1.567-.508h-3.361Zm3.305 3.185h-2.38v-2.473h2.21c.564 0 .944.109 1.139.327.196.21.294.527.294.949 0 .353-.098.643-.294.87-.188.218-.511.327-.969.327Zm.079 3.388h-2.459v-2.688h2.312c.594 0 1 .109 1.218.327.225.211.338.527.338.949 0 .512-.113.877-.338 1.095-.226.211-.583.317-1.071.317Z"
        clipRule="evenodd"
      />
      <path
        fill="#38FF93"
        d="M147.54 29.273V22h4.905v.734h-3.98v2.541h3.575v.723h-3.575v2.552h3.98v.723h-4.905ZM155.983 22.734v6.539h.925v-6.539h2.413V22h-5.751v.734h2.413Z"
      />
      <path
        fill="#38FF93"
        fillRule="evenodd"
        d="m162.257 22-2.763 7.273h.959l.766-2h3.158l.767 2h.947L163.339 22h-1.082Zm1.951 4.54h-2.831l1.41-3.738 1.421 3.738Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1="16.108"
          x2="16.108"
          y1="0"
          y2="30.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#151617" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
