import { Address, erc20Abi } from 'viem';
import { useReadContract } from 'wagmi';

import { ZERO_ADDRESS } from '../../../constants/web3';

export function useAllowanceReadMethod(
  myAddress: Address | undefined,
  contractSpenderAddress: Address | undefined,
) {
  return useReadContract({
    address: import.meta.env.VITE_TOKEN_CONTRACT_ADDRESS as Address,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [myAddress ?? ZERO_ADDRESS, contractSpenderAddress ?? ZERO_ADDRESS],
    query: {
      enabled: !!myAddress && !!contractSpenderAddress,
    },
  });
}
