import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from 'react-share';

import { Box, IconButton, TextField, Typography } from '@mui/material';

import {
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterGrayIcon,
} from '../../../icons';
import { CopyClipboardButton } from '../../Buttons/CopyClipboardButton';

interface ShareModalBodyProps {
  title: string[] | string;
  shareLink: string;
}

export function CustomIconButton({ children }: { children: React.ReactNode }) {
  return (
    <IconButton
      sx={{
        bgcolor: '#2E3033',
        p: 2,
        width: 48,
        height: 48,
        stroke: 'none',
      }}
    >
      {children}
    </IconButton>
  );
}

export function ShareModalBody({ title, shareLink }: ShareModalBodyProps) {
  return (
    <div>
      {Array.isArray(title) ? (
        title.map((line, index) => (
          <Typography key={index} variant="body1">
            {line}
          </Typography>
        ))
      ) : (
        <Typography variant="body1">{title}</Typography>
      )}
      <TextField
        type="text"
        sx={{
          pt: 3,
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
          '& .MuiInputBase-input': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: '16px',
          },
        }}
        InputProps={{
          readOnly: true,
          sx: { height: { xs: '50px', md: '56px' } },
          endAdornment: (
            <CopyClipboardButton
              aria-label="Copy link to share"
              edge="end"
              textToCopy={shareLink}
            />
          ),
        }}
        value={shareLink}
        inputProps={{ title: shareLink }}
      />
      <Box
        sx={{
          pt: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: 2, md: 0 },
        }}
      >
        <TwitterShareButton url={shareLink}>
          <CustomIconButton>
            <TwitterGrayIcon />
          </CustomIconButton>
        </TwitterShareButton>

        <FacebookShareButton url={shareLink}>
          <CustomIconButton>
            <FacebookIcon />
          </CustomIconButton>
        </FacebookShareButton>

        <TelegramShareButton url={shareLink}>
          <CustomIconButton>
            <TelegramIcon stroke={'#9B9FA3'} />
          </CustomIconButton>
        </TelegramShareButton>

        <RedditShareButton url={shareLink}>
          <CustomIconButton>
            <RedditIcon />
          </CustomIconButton>
        </RedditShareButton>

        <LinkedinShareButton url={shareLink}>
          <CustomIconButton>
            <LinkedinIcon />
          </CustomIconButton>
        </LinkedinShareButton>
      </Box>
    </div>
  );
}
