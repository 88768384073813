import { Box, Button, Skeleton, Typography } from '@mui/material';

import useGlobalModal from '../hooks/useGlobalModal';
import { formatMultiplier } from '../lib/formatMultiplier';
import { useCountdown } from '../providers/CountdownProvider';

import { RocketLaunch } from './icons';

export function RefCompaignSection() {
  const { showModal } = useGlobalModal();
  const { timeLeft, multiplier, isLoading: countdownLoading } = useCountdown();

  const handleGetMyLinkClick = () => {
    showModal({ modalType: 'ReferFriendsModal' });
  };

  if (countdownLoading) {
    return <Skeleton variant="rounded" sx={{ height: { xs: 100, md: 158 } }} />;
  }

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'row', md: 'column' }}
      gap={4}
      p={4}
      borderRadius={{ xs: 0, md: 3 }}
      sx={{
        backgroundColor: '#182920',
        maxWidth: { xs: '100%', md: '272px' },
      }}
    >
      <Box display="flex" gap={4} alignItems={{ xs: 'center', md: 'start' }}>
        <RocketLaunch />

        <Box display="flex" flexDirection="column" gap={1}>
          {timeLeft && (
            <Typography
              color="primary"
              sx={{
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {timeLeft.days} d : {timeLeft.hours} h : {timeLeft.minutes} m :{' '}
              {timeLeft.seconds} s
            </Typography>
          )}
          {
            <Typography
              component="span"
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: 400,
              }}
            >
              Refer NOW and get{' '}
              <Typography component="span" color="primary">
                {formatMultiplier(multiplier)}
              </Typography>{' '}
              boost on your points
            </Typography>
          }
        </Box>
      </Box>

      <Button
        variant="outlined"
        size="large"
        sx={{ width: { xs: '150px', md: '100%' }, alignSelf: 'center' }}
        onClick={handleGetMyLinkClick}
      >
        Get my link
      </Button>
    </Box>
  );
}
