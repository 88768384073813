import { Box, Typography } from '@mui/material';

import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';

import { ShareModalBody } from './Content/ShareModalBody';

export interface ShareLinkModalProps {
  title: string;
  shareLink: string;
}

export function ShareLinkModal({ title, shareLink }: ShareLinkModalProps) {
  const { hideModal } = useGlobalModal();

  const onClose = () => {
    hideModal();
  };

  return (
    <ModalContainer
      aria-label={`${title || 'Share link'} modal`}
      aria-description={`${title || 'Share link'} modal`}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h3" component={'span'} sx={{ marginBottom: 5 }}>
          {title || 'Share link'}
        </Typography>

        <ShareModalBody 
          title={[
            "With this link, your followers can subscribe",
            "and join your private community."
          ]}
          shareLink={shareLink} 
        />
      </Box>
    </ModalContainer>
  );
}
