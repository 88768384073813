import { useAccount, useDisconnect } from 'wagmi';

import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';

import {
  CopyIcon,
  LinkBreakIcon,
  MyWalletIcon,
  WalletIcon,
} from '../components/icons';
import { LogoWithText } from '../components/LogoWithText';
import { ButtonMenu } from '../components/shared/ButtonMenu';
import { CoinbaseSmartWalletButton } from '../components/shared/Buttons/CoinbaseSmartWalletButton';
import { COINBASE_SMART_WALLET_LINK } from '../constants/externalLinks';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import {
  GlobalConnectErrors,
  useGlobalConnectError,
} from '../hooks/useWalletConnectError';
import { truncAddress } from '../lib/truncHash';

export default function MobileHeader() {
  const { data: userData } = useCurrentUserData();
  const { disconnect } = useDisconnect();
  const { copyToClipBoard } = useCopyToClipboard();
  const { address, isConnecting } = useAccount();
  const { errorType } = useGlobalConnectError();

  const showWalletDetails =
    errorType === null ||
    errorType === GlobalConnectErrors.EmailNotLinked ||
    errorType === GlobalConnectErrors.DifferFromLinkedWallet;

  const handleMyWallet = () => {
    window.open(COINBASE_SMART_WALLET_LINK, '_blank');
  };

  const handleCopyAddress = () => {
    if (!address) {
      return;
    }

    copyToClipBoard(address, `Wallet address copied.`);
  };

  const customButton = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: '#262829',
        p: 4,
        borderRadius: '44px',
        height: '36px',
        gap: 2,
      }}
    >
      <WalletIcon width={16} height={16} />
      <Typography fontSize="12px" color="#F0F5FA">
        {truncAddress(userData?.wallet_address)}
      </Typography>
    </Box>
  );

  return (
    <Box
      display="flex"
      sx={{
        position: 'fixed',
        width: '100%',
        height: '56px',
        justifyContent: 'space-between',
        zIndex: 1000,
        pt: 5,
        px: 5,
      }}
    >
      <LogoWithText width={150} height={30} />
      {errorType === GlobalConnectErrors.NoWalletConnected && (
        <CoinbaseSmartWalletButton
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 4,
            borderRadius: '44px',
            height: '36px',
            gap: 2,
          }}
        />
      )}
      {showWalletDetails && (
        <ButtonMenu title="Wallet menu" customButton={customButton}>
          <MenuList>
            <MenuItem onClick={handleCopyAddress}>
              <ListItemIcon
                sx={{
                  svg: {
                    fill: 'white',
                    width: 24,
                    height: 24,
                  },
                }}
              >
                <CopyIcon />
              </ListItemIcon>
              Copy address
            </MenuItem>

            <MenuItem onClick={handleMyWallet}>
              <ListItemIcon>
                <MyWalletIcon />
              </ListItemIcon>
              My wallet
            </MenuItem>

            <MenuItem onClick={() => disconnect()}>
              <ListItemIcon>
                <LinkBreakIcon />
              </ListItemIcon>
              Disconnect
            </MenuItem>
          </MenuList>
        </ButtonMenu>
      )}
    </Box>
  );
}
